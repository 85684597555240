import React, { PureComponent } from 'react';
// import { LanguageUtils } from '../../utils'
import { dispatch } from 'redux.js';
import './index.css';
import $ from 'jquery';
import _ from "lodash";
import { widget } from '../../charting_library/charting_library.esm';
import Datafeed from './datafeed.js';
// import config from '../../combineConfig';
import { emitter } from 'utils/EventEmitter';
import * as actions from "../../store/actions";
import * as queryString from 'query-string';
// import axios from '../../../src/axios';
import { history } from '../../redux';
import Axios from 'axios';
const globalVar = window._env_;

const SAVE_MODE_LOCAL_STORAGE = true

const KEY_SAVE_CHARTS_LOCAL_STORAGE = `SAVE_CHARTS_LOCAL#public_user_id`
const KEY_SAVE_STUDY_TMP_LOCAL_STORAGE = `SAVE_STUDY_TMP_LOCAL#$public_user_id`
const KEY_SAVE_PREV_STUDY = `KEY_SAVE_PREV_STUDY`

function savePrevStudy(obj) {
    try {
        if (!_.isEmpty(obj)) {
            localStorage.setItem(`${KEY_SAVE_PREV_STUDY}`, JSON.stringify(obj))
        }
    } catch (error) {
    }
}

function getPrevStudy() {
    try {
        let dataPrevStudy = localStorage.getItem(`${KEY_SAVE_PREV_STUDY}`)
        if (dataPrevStudy && dataPrevStudy.length !== 0) {
            dataPrevStudy = JSON.parse(dataPrevStudy)
            return JSON.parse(dataPrevStudy) ? JSON.parse(dataPrevStudy) : {}
        }
        return {}
    } catch (error) {
        return {}
    }
}

function deletePrevStudy(src) {
    try {
        let dataPrevStudy = localStorage.getItem(`${KEY_SAVE_PREV_STUDY}`)
        const convertDataPrevStudy = JSON.parse(dataPrevStudy)

        if (_.isEqual(src, convertDataPrevStudy)) {
            localStorage.removeItem(`${KEY_SAVE_PREV_STUDY}`)
        }
    } catch (error) {
    }
}


export class TVChartContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showChart: false
        }
        this.listenToTheEmitter();
    }

    static defaultProps = {
        interval: 'D',
        containerId: 'tv_chart_container',
        datafeedUrl: 'https://demo_feed.tradingview.com',
        libraryPath: globalVar.app.ROUTER_BASE_NAME != null ? '/' + globalVar.app.ROUTER_BASE_NAME + 'charting_library/' : '/charting_library/',
        chartsStorageUrl: `${globalVar.api.API_BASE_URL}userdata`,
        chartsStorageApiVersion: 'v1',
        clientId: globalVar.api.CLIENT_ID,
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {
            // "volume.volume.color.0": "#FF007A",
            // "volume.volume.color.1": "#8ec919",
            // "volume.volume.transparency": 70,
            // "volume.volume ma.color": "#F5BC00",
            // "volume.volume ma.transparency": 30,
            // "volume.volume ma.linewidth": 5,
            // "volume.volume ma.plottype": "line",
            // "volume.show ma": true,
            // "volume.options.showStudyArguments": false,
            // "bollinger bands.median.color": "#33FF88",
            // "bollinger bands.upper.linewidth": 7
        },
        timeframe: '2m',
        overrides: {
            "paneProperties.background": "#131313",
            "paneProperties.vertGridProperties.color": "#28343C",
            "paneProperties.horzGridProperties.color": "#28343C",
            "scalesProperties.lineColor": "#555555",
            "scalesProperties.textColor": "#999999",
            "header_widget.background": "#131313",
            //	Candles styles
            "mainSeriesProperties.candleStyle.upColor": "#8ec919",
            "mainSeriesProperties.candleStyle.downColor": "#FF007A",
            "mainSeriesProperties.candleStyle.drawWick": true,
            "mainSeriesProperties.candleStyle.drawBorder": true,
            "mainSeriesProperties.candleStyle.borderColor": "#378658",
            "mainSeriesProperties.candleStyle.borderUpColor": "#8ec919",
            "mainSeriesProperties.candleStyle.borderDownColor": "#FF007A",
            "mainSeriesProperties.candleStyle.wickUpColor": 'rgba(142, 201, 25, 1)',
            "mainSeriesProperties.candleStyle.wickDownColor": 'rgba(255, 0, 122, 1)',
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false
        }
    };

    IS_CHANGE_THEME = true;
    tvWidget = null;
    tvWidgetReady = false;

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    
    callback = (defaultTheme) => {
        defaultTheme = defaultTheme.toLowerCase()
        if (this.props.defaultTheme !== defaultTheme) {
            if (this.tvWidget) {
                this.initWidget(true);
            }

        }
    }
    
    listenToTheEmitter() {
        emitter.on("onReadyChartByDefaultTheme", this.callback);
    }

    load_overrides = () => {
        let { defaultTheme } = this.props;
        let objOverrides = {}
        let objStudiesOverrides = {}
        if (defaultTheme === "light") {
            objOverrides = {
                "paneProperties.background": "#fff",
                "paneProperties.backgroundType": "solid",
                "paneProperties.vertGridProperties.color": "#E6E6E6",
                "paneProperties.horzGridProperties.color": "#E6E6E6",
                "scalesProperties.lineColor": "#555555",
                "scalesProperties.textColor": "#999999",
                "header_widget.background": "#E6E6E6",
                "toolbar_bg": "#ffffff",
                "paneProperties.topMargin": 20, // fix lỗi đồ thị cao quá khung,

                "mainSeriesProperties.candleStyle.upColor": "#53B987",
                "mainSeriesProperties.candleStyle.borderUpColor": "#53B987",

                "mainSeriesProperties.candleStyle.downColor": "#EB4D5C",
                "mainSeriesProperties.candleStyle.borderDownColor": "#EB4D5C",

                "mainSeriesProperties.priceLineWidth": 1,
                "loading_screen_color": "#fff"
            }

            objStudiesOverrides = {
                "volume.volume.color.0": "#eb4d5c",
                "volume.volume.color.1": "#53b987",
                "volume.volume.transparency": 65,
            }
        }
        if (defaultTheme === "dark") {
            objOverrides = {
                // "paneProperties.background": "#131313",
                "paneProperties.background": "#282B30",
                "paneProperties.backgroundType": "solid",
                "paneProperties.vertGridProperties.color": "#34383E",
                "paneProperties.horsGridProperties.color": "#34383E",
                "scalesProperties.lineColor": "#555555",
                "scalesProperties.textColor": "#999999",
                // "header_widget.background": "#131313",
                // "header_widget.background": "#282B30",
                // "toolbar_bg": "#13161B",
                "toolbar_bg": "#282B30",

                "paneProperties.topMargin": 20, // fix lỗi đồ thị cao quá khung

                // "mainSeriesProperties.candleStyle.upColor": "#0f0",
                // "mainSeriesProperties.candleStyle.borderUpColor": "#0f0",

                // "mainSeriesProperties.candleStyle.downColor": "#f00",
                // "mainSeriesProperties.candleStyle.borderDownColor": "#f00",

                // "mainSeriesProperties.candleStyle.upColor": "#25A69A",
                // "mainSeriesProperties.candleStyle.borderUpColor": "#25A69A",

                // "mainSeriesProperties.candleStyle.upColor": "#21DB77",
                // "mainSeriesProperties.candleStyle.borderUpColor": "#21DB77",

                // "mainSeriesProperties.candleStyle.downColor": "#F93E3E",
                // "mainSeriesProperties.candleStyle.borderDownColor": "#F93E3E",

                "mainSeriesProperties.candleStyle.upColor": "#50FF00",
                "mainSeriesProperties.candleStyle.borderUpColor": "#50FF00",

                "mainSeriesProperties.candleStyle.downColor": "#F92626",
                "mainSeriesProperties.candleStyle.borderDownColor": "#F92626",

                "mainSeriesProperties.priceLineWidth": 1,
                "loading_screen_color": "#282B30"
            }

            objStudiesOverrides = {
                // "volume.volume.color.0": "#0f0",
                // "volume.volume.color.1": "#f00",
                // "volume.volume.color.0": "#EB4D5C",
                // "volume.volume.color.1": "#53B987",
                "volume.volume.color.0": "#F92626",
                "volume.volume.color.1": "#50FF00",
                "volume.volume.transparency": 65,
            }
        }
        return { objOverrides, objStudiesOverrides }
    }

    initWidget = (action) => {

        var pathname = history.location.pathname;
        const { currentAccount, currentSymbol, language, defaultTheme, show } = this.props;

        const genUrlSaveChart = (path) => `${this.props.chartsStorageUrl}/${this.props.chartsStorageApiVersion}/${path}?`
        const queryParams = {
            // client: this.props.clientIdExport && this.props.clientIdExport.id ? this.props.clientIdExport.id : this.props.clientId,
            // user: currentAccount && currentAccount.id ? currentAccount.id : this.props.userId
        }

        let chartsInLocalStorage = []
        let studyTmpInLocalStorage = []
        try {
            const dataChartsInLocalStorage = localStorage.getItem(`${KEY_SAVE_CHARTS_LOCAL_STORAGE}`)
            const dataStudyTmpInLocalStorage = localStorage.getItem(`${KEY_SAVE_STUDY_TMP_LOCAL_STORAGE}`)

            chartsInLocalStorage = dataChartsInLocalStorage
                ? JSON.parse(dataChartsInLocalStorage) && JSON.parse(dataChartsInLocalStorage).length !== 0
                    ? JSON.parse(dataChartsInLocalStorage) : [] : []

            studyTmpInLocalStorage = dataStudyTmpInLocalStorage
                ? JSON.parse(dataStudyTmpInLocalStorage) && JSON.parse(dataStudyTmpInLocalStorage).length !== 0
                    ? JSON.parse(dataStudyTmpInLocalStorage) : [] : []
        } catch (error) {
            chartsInLocalStorage = []
            studyTmpInLocalStorage = []
        }

        let self = this
        let overrides = this.load_overrides()

        let disabledFeaturesRaw = ["use_localstorage_for_settings", "header_screenshot", "go_to_date", "volume_force_overlay", "display_market_status","header_symbol_search"]
        let enabledFeaturesRaw = ["study_templates", "side_toolbar_in_fullscreen_mode", "caption_buttons_text_if_possible", "header_fullscreen_button"]
        if (show && show === 'm') {
            disabledFeaturesRaw = ["widget_logo", "header_widget", "header_interval_dialog_button", "border_around_the_chart",
                "header_symbol_search", "display_market_status", "create_volume_indicator_by_default", "left_toolbar",
                "go_to_date", "adaptive_logo", "control_bar", "use_localstorage_for_settings", "header_screenshot",
                "header_fullscreen_button", "volume_force_overlay", "header_settings", "control_bar", "timeframes_toolbar"]
            enabledFeaturesRaw = ["side_toolbar_in_fullscreen_mode"]
        }

        if (show && show === 'd') {
            disabledFeaturesRaw = ["widget_logo", "header_interval_dialog_button", "header_undo_redo", "header_saveload", "border_around_the_chart", "display_market_status", "adaptive_logo", "use_localstorage_for_settings", "header_fullscreen_button", "volume_force_overlay", "header_symbol_search", "header_settings", "header_screenshot"]
            enabledFeaturesRaw = ["side_toolbar_in_fullscreen_mode"]
        }

        const overrideSaveFn = {
            charts: chartsInLocalStorage,
            studyTemplates: studyTmpInLocalStorage,
            drawingTemplates: [],
            //overwrite
            getAllCharts: function () {
                return Promise.resolve(this.charts);
            },

            saveChart: function (chartData) {
                if (!chartData.id) {
                    chartData.id = `${Math.random().toString()}_${new Date().getTime()}`
                } else {
                    this.removeChart(chartData.id);
                }

                chartData.timestamp = new Date().valueOf();

                this.charts.push(chartData);

                localStorage.setItem(`${KEY_SAVE_CHARTS_LOCAL_STORAGE}`, JSON.stringify(this.charts))
                return Promise.resolve(chartData.id);
            },

            removeChart: function (id) {
                for (var i = 0; i < this.charts.length; ++i) {
                    if (this.charts[i].id === id) {
                        this.charts.splice(i, 1);
                        localStorage.setItem(`${KEY_SAVE_CHARTS_LOCAL_STORAGE}`, JSON.stringify(this.charts))
                        return Promise.resolve();
                    }
                }

                return Promise.reject();
            },

            getChartContent: async function (id) {
                for (var i = 0; i < this.charts.length; ++i) {
                    if (this.charts[i].id === id) {
                        await dispatch(actions.setSymbolSearchDataFeed(true))
                        return Promise.resolve(this.charts[i].content);
                    }
                }

                console.error('error');

                return Promise.reject();
            },

            getAllStudyTemplates: async function () {
                // Gọi api lấy studytemplate (trả về template mặc đinh do không có token) và merge với list studytemplate ở localstorage
                let studyInLocalStorage = [];
                let dataStudyTmpInLocalStorage = localStorage.getItem(`${KEY_SAVE_STUDY_TMP_LOCAL_STORAGE}`);
                studyInLocalStorage = dataStudyTmpInLocalStorage
                    ? JSON.parse(dataStudyTmpInLocalStorage) && JSON.parse(dataStudyTmpInLocalStorage).length !== 0
                        ? JSON.parse(dataStudyTmpInLocalStorage) : [] : []


                const res = await Axios.get(`${genUrlSaveChart('study_templates')}${queryString.stringify(queryParams)}`).then((result) => {
                    let defaultStudyTemplate = result && result.data && result.data.data ? result.data.data : [];
                    defaultStudyTemplate.length > 0 && defaultStudyTemplate.forEach(element => {
                        element['isDefault'] = true;
                    });
                    this.studyTemplates = defaultStudyTemplate.length > 0 ? defaultStudyTemplate.concat(studyInLocalStorage) : studyInLocalStorage;
                    // this.studyTemplates = studyTmpInLocalStorage;
                    // //console.log('laojahackgame=====> GET STUDY TEMPLATE NOT LOGIN', this.studyTemplates);
                    return this.studyTemplates;
                })

                return Promise.resolve(res);
            },

            saveStudyTemplate: function (studyTemplateData) {
                // Loại các template mặc định khỏi this.template để lưu (Người dùng có thể lưu template trùng tên với template mặc định)
                let studyTemplatesToSave = this.studyTemplates.filter(item => {
                    return !item['isDefault'];
                })


                for (var i = 0; i < studyTemplatesToSave.length; ++i) {
                    if (studyTemplatesToSave[i].name === studyTemplateData.name) {
                        studyTemplatesToSave.splice(i, 1);

                        localStorage.setItem(`${KEY_SAVE_STUDY_TMP_LOCAL_STORAGE}`, JSON.stringify(studyTemplatesToSave))
                        break;
                    }
                }

                studyTemplatesToSave.push(studyTemplateData);
                localStorage.setItem(`${KEY_SAVE_STUDY_TMP_LOCAL_STORAGE}`, JSON.stringify(studyTemplatesToSave))
                savePrevStudy(studyTemplateData.content ? studyTemplateData.content : {})
                return Promise.resolve();
            },

            removeStudyTemplate: function (studyTemplateData) {
                // Loại các template mặc định khỏi this.template để lưu (Người dùng có thể lưu template trùng tên với template mặc định)
                let studyTemplatesToSave = this.studyTemplates.filter(item => {
                    return !item['isDefault'];
                })

                for (var i = 0; i < studyTemplatesToSave.length; ++i) {
                    if (studyTemplatesToSave[i].name === studyTemplateData.name) {
                        deletePrevStudy(this.studyTemplates[i].content)
                        studyTemplatesToSave.splice(i, 1);

                        localStorage.setItem(`${KEY_SAVE_STUDY_TMP_LOCAL_STORAGE}`, JSON.stringify(studyTemplatesToSave));
                        return Promise.resolve();
                    }
                }

                return Promise.reject();
            },

            getStudyTemplateContent: async function (studyTemplateData) {
                for (var i = 0; i < this.studyTemplates.length; ++i) {
                    if (this.studyTemplates[i].name === studyTemplateData.name) {
                        savePrevStudy(this.studyTemplates[i].content)
                        let parseData = JSON.parse(this.studyTemplates[i].content)
                        if (parseData && parseData.hasOwnProperty('symbol')) {
                            await dispatch(actions.updatedSymbolLayoutPage(parseData.symbol, "search_layout"));
                        }
                        return Promise.resolve(this.studyTemplates[i].content);
                    }
                }

                console.error('st: error');

                return Promise.reject();
            },

            // not use
            removeDrawingTemplate: function (toolName, templateName) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        this.drawingTemplates.splice(i, 1);
                        return Promise.resolve();
                    }
                }
                return Promise.reject();
            },

            loadDrawingTemplate: function (toolName, templateName) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        return Promise.resolve(this.drawingTemplates[i].content);
                    }
                }
                console.error('drawing: error');
                return Promise.reject();
            },

            saveDrawingTemplate: function (toolName, templateName, content) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        this.drawingTemplates.splice(i, 1);
                        break;
                    }
                }
                this.drawingTemplates.push({ name: templateName, content: content });
                return Promise.resolve();
            },

            getDrawingTemplates: function () {
                return Promise.resolve(this.drawingTemplates.map(function (template) {
                    return template.name;
                }));
            },
            // not use
        }

        // Lấy accessToken cho các function của chart và template
        let iframeToken = '';
        if (this.props.isLoggedIn) {
            let currentLoginUserName = sessionStorage.getItem('activeCustID') || '';
            let savedUserToken = JSON.parse(localStorage.getItem('token-users')) || {};
            let authInfo = savedUserToken[`${currentLoginUserName}`];
            if (authInfo) {
                iframeToken = authInfo['access_token'] || '';
            }
        }

        const overrideSaveFnExport = {
            charts: [],
            studyTemplates: [],
            drawingTemplates: [],

            //overwrite
            getAllCharts: async function () {
                const headers = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.get(`${genUrlSaveChart('charts')}${queryString.stringify(queryParams)}`, headers).then((result) => {
                    this.charts = result && result.data && result.data.data ? result.data.data : []
                    return this.charts
                })
                return Promise.resolve(res);
            },

            saveChart: async function (chartData) {
                if (!chartData.id) {
                    chartData.id = `${Math.random().toString()}_${new Date().getTime()}`
                } else {
                    this.removeChart(chartData.id);
                }

                chartData.timestamp = new Date().valueOf();

                const formData = new FormData()
                formData.append('name', chartData.name)
                formData.append('content', chartData.content)
                formData.append('symbol', chartData.symbol)
                formData.append('resolution', chartData.resolution)

                const config = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.post(`${genUrlSaveChart('charts')}${queryString.stringify(queryParams)}`, formData, config).then((result) => {
                    return result && result.data && result.data.id ? result.data.id : chartData.id
                })
                this.charts.push({ ...chartData, id: res });
                return Promise.resolve(res);
            },


            removeChart: async function (id) {

                const config = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.delete(`${genUrlSaveChart('charts')}${queryString.stringify({ ...queryParams, chart: id })}`, config).then((result) => {
                    let data = result && result.data ? result.data : []
                    return data
                }).catch(error => {
                    return Promise.reject(error)
                })

                for (var i = 0; i < this.charts.length; ++i) {
                    if (this.charts[i].id === id) {
                        this.charts.splice(i, 1);
                        return Promise.resolve(res);
                    }
                }
            },

            getChartContent: async function (id) {

                const config = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.get(`${genUrlSaveChart('charts')}${queryString.stringify({ ...queryParams, chart: id })}`, config).then((result) => {
                    let data = result && result.data && result.data.data ? result.data.data : {}
                    return data.content
                }).catch(error => {
                    console.error('error');
                    return Promise.reject(error)
                })
                await dispatch(actions.setSymbolSearchDataFeed(true))
                return Promise.resolve(res);
            },

            getAllStudyTemplates: async function () {
                const headers = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.get(`${genUrlSaveChart('study_templates')}${queryString.stringify(queryParams)}`, headers).then((result) => {
                    this.studyTemplates = result && result.data && result.data.data ? result.data.data : []
                    return this.studyTemplates
                })
                return Promise.resolve(res)

            },

            saveStudyTemplate: async function (studyTemplateData) {
                for (var i = 0; i < this.studyTemplates.length; ++i) {
                    if (this.studyTemplates[i].name === studyTemplateData.name) {
                        this.studyTemplates.splice(i, 1);
                        break;
                    }
                }

                const formData = new FormData()
                formData.append('name', studyTemplateData.name)
                formData.append('content', studyTemplateData.content)

                const config = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };
                const res = await Axios.post(`${genUrlSaveChart('study_templates')}${queryString.stringify(queryParams)}`, formData, config).then((result) => {
                    let data = result && result.data && result.data.data ? result.data.data : []
                    return data
                })
                this.studyTemplates.push(studyTemplateData);
                savePrevStudy(studyTemplateData.content ? studyTemplateData.content : {})

                return Promise.resolve(res);
            },

            removeStudyTemplate: async function (studyTemplateData) {
                for (var i = 0; i < this.studyTemplates.length; ++i) {
                    if (this.studyTemplates[i].name === studyTemplateData.name) {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${iframeToken}`
                            }
                        };
                        const res = await Axios.delete(`${genUrlSaveChart('study_templates')}${queryString.stringify({ ...queryParams, template: this.studyTemplates[i].name })}`, config).then((result) => {
                            let data = result && result.data ? result.data : []
                            deletePrevStudy(this.studyTemplates[i].content)
                            this.studyTemplates.splice(i, 1);
                            return data
                        }).catch(error => {
                            return Promise.reject();
                        })
                        return Promise.resolve(res);
                    }
                }
            },

            getStudyTemplateContent: async function (studyTemplateData) {

                const config = {
                    headers: {
                        Authorization: `Bearer ${iframeToken}`
                    }
                };

                const res = await Axios.get(`${genUrlSaveChart('study_templates')}${queryString.stringify({ ...queryParams, template: studyTemplateData.name })}`, config).then((result) => {
                    let data = result && result.data ? result.data : []
                    return data.data.content
                }).catch(error => {
                    console.error('st: error');
                    return Promise.reject();
                })
                savePrevStudy(res)
                let parseData = JSON.parse(res)
                if (parseData && parseData.hasOwnProperty('symbol')) {
                    await dispatch(actions.updatedSymbolLayoutPage(parseData.symbol, "search_layout"));
                }
                return Promise.resolve(res);
            },

            // not use
            removeDrawingTemplate: function (toolName, templateName) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        this.drawingTemplates.splice(i, 1);
                        return Promise.resolve();
                    }
                }
                return Promise.reject();
            },

            loadDrawingTemplate: function (toolName, templateName) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        return Promise.resolve(this.drawingTemplates[i].content);
                    }
                }
                console.error('drawing: error');
                return Promise.reject();
            },

            saveDrawingTemplate: function (toolName, templateName, content) {
                for (var i = 0; i < this.drawingTemplates.length; ++i) {
                    if (this.drawingTemplates[i].name === templateName) {
                        this.drawingTemplates.splice(i, 1);
                        break;
                    }
                }
                this.drawingTemplates.push({ name: templateName, content: content });
                return Promise.resolve();
            },

            getDrawingTemplates: function () {
                return Promise.resolve(this.drawingTemplates.map(function (template) {
                    return template.name;
                }));
            },
            // not use
        }


        let _symbol = currentSymbol ? currentSymbol.id : ' ' // Hiển thị mặc định giao diện ngay cả khi không có currentSymbol
        let widgetOptions = {
            symbol: _symbol,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: Datafeed,
            interval: this.props.interval,
            // container_id: this.props.containerId,
            container_id: `${this.props.containerId}${this.props.callerId}`,
            library_path: this.props.libraryPath,

            locale: language || 'en',
            charts_storage_url: this.props.chartsStorageUrl,
            charts_storage_api_version: this.props.chartsStorageApiVersion,
            client_id: this.props.clientId,
            user_id: currentAccount ? currentAccount.id : this.props.userId,
            fullscreen: this.props.fullscreen,
            autosize: this.props.autosize,
            studies_overrides: overrides.objStudiesOverrides,
            disabled_features: disabledFeaturesRaw,
            enabled_features: enabledFeaturesRaw,
            // toolbar_bg: '#000000',
            // toolbar_bg: '#131313',
            // toolbar_bg: overrides.objOverrides.toolbar_bg,
            timeframe: '2m',
            timezone: 'Asia/Bangkok',
            // overrides: this.props.overrides,
            // overrides: { ...this.props.overrides, newOverrides },
            overrides: overrides.objOverrides,
            drawings_access: {
                type: 'black',
                // type: defaultTheme === "light" ? "Light" : "Dark",
                type: "black",
                tools: [{
                    name: "Trend Line"
                }]
            },
            loading_screen: {
                backgroundColor: overrides.objOverrides.loading_screen_color,
                // foregroundColor: overrides.objOverrides.loading_screen_color
            },
            theme: defaultTheme === "light" ? "Light" : "Dark",
            custom_css_url: process.env.PUBLIC_URL + '/charting_library/index_custom.css',
            save_load_adapter: this.props.isLoggedIn ? overrideSaveFnExport : overrideSaveFn,
            // durations: [{ name: 'DAY', value: 'DAY' }, {   name: 'WEEK', value: 'WEEK', default: true }, { name: 'GTC', value: 'GTC' }]
            // symbol_search_complete: (symbol) => {
            //     try {
            //         return new Promise((res, rej) => {
            //             if (!symbol) {
            //                 return res('');
            //             }
            //             // dispatch(actions.updatedSymbolLayoutPage(symbol));
            //             return res(symbol);
            //         })
            //     } catch (err) {

            //     }
            // }
        };

        // if (this.props.isLoggedIn) {
        //     delete widgetOptions.save_load_adapter
        // }

        //khi wiget bị render nhiều lần, cần check element trước khi render
        if (widgetOptions.container_id && !action) {
            let elementExists = document.getElementById(widgetOptions.container_id);
            if (!elementExists) return;
        }

        // noinspection JSPotentiallyInvalidConstructorUsage
        const tvWidget = new widget(widgetOptions);
        this.tvWidget = tvWidget;
        tvWidget.onChartReady(() => {
            // this.tvWidgetReady.addCustomCSSFile('this.tvWidgetReady/test.css')
            this.tvWidgetReady = true;


            // Bắt thay đổi nếu symbol trong chart, không bao gồm event tìm kiếm trong so sánh (có thể để dispatch trong này)
            self.tvWidget.activeChart().onSymbolChanged().subscribe(null, () => {


                let chartSymbol = tvWidget.activeChart().symbol();
                //console.log('The symbol is changed 1', chartSymbol, tvWidget.activeChart().symbol());

                if (chartSymbol) {

                    let symbol = chartSymbol.split(':');

                    if (symbol.length > 1) {
                        symbol = symbol[1]
                    } else {
                        symbol = symbol[0]
                    }
                    //console.log('The symbol is changed', this.props.isCheckSymbolDataFeed, { chartSymbol: tvWidget.activeChart().symbol(), symbol });
                    // dispatch(actions.updatedSymbolLayoutPage(symbol, "search_layout", this.props.currentLayoutPageActive || ''));
                    if (this.props.isCheckSymbolDataFeed) {
                        //console.log('The symbol is changed 2222');
                        dispatch(actions.updatedSymbolLayoutPage(symbol, "search_layout"));
                    }
                }

            });


            if (!pathname.includes('TChart')) {

                // try {
                //     var button = self.tvWidget.createButton({ align: "left" });

                //     button.setAttribute('title', 'So sánh');
                //     button.addEventListener('click', function () {

                //         self.tvWidget.chart().executeActionById("compareOrAdd");
                //     });
                //     button.textContent = 'So sánh 2'
                // } catch (err) {


                // }
            }
            else {
                // set study_template mặc định của biểu đồ kỹ thuật (template đầu tiên trong danh sách study_template)
                // widgetOptions.user_id = 0
                const getListStudyTemplate = `${self.props.chartsStorageUrl}/${self.props.chartsStorageApiVersion}/study_templates?client=${self.props.clientId}&user=${widgetOptions.user_id}`;
                fetch(getListStudyTemplate).then(result => result.json()).then(res => {
                    if (res && 'ok' === res.status && res.data.length > 0) {
                        let chartName = res.data[0].name;
                        // chartName = 10
                        const loadStudyTemplate = `${self.props.chartsStorageUrl}/${self.props.chartsStorageApiVersion}/charts?client=${self.props.clientId}&user=${widgetOptions.user_id}&chart=${chartName}`;
                        fetch(loadStudyTemplate).then(result => result.json()).then(res => {
                            try {
                                let template = JSON.parse(res.data.content);
                                if (template) {
                                    self.tvWidget.chart().applyStudyTemplate(template); // Áp dụng template đầu tiên
                                }
                            } catch (error) {
                                //console.log('TVChart: Error when parsing template data')
                            }

                        });
                    }
                });
                // Haki.: Có thể bổ sung thêm đoạn thay icon "Mở rộng" thành "Thu nhỏ"
            }
            this._setState({ showChart: true })

            try {

                const studyTemplate = getPrevStudy()
                if (!_.isEmpty(studyTemplate)) {
                    if (studyTemplate && studyTemplate.hasOwnProperty('symbol')) {
                        delete studyTemplate.symbol
                    }
                    //console.log('ha_check_studyTemplate', studyTemplate, self.tvWidget);
                    studyTemplate && self.tvWidget.chart().applyStudyTemplate(studyTemplate)
                }
            } catch (error) {

            }
        });
        window.addEventListener('storage', self.callbackWindow);
    };

    callbackWindow = async (e) => {
        let self = this
        if (self.IS_CHANGE_THEME) {
            await self.changeClassCustom(self.currentTheme, 'dark');
            self.IS_CHANGE_THEME = false;
        }
    };

    async changeClassCustom(defaultTheme, prevTheme) {
        defaultTheme = this.props.defaultTheme
        // let iframe = $("#tv_chart_container iframe").contents()
        let iframe = $('#' + this.props.containerId + this.props.callerId + ' iframe').contents()
        let _html = iframe.find("html");
        let _head = iframe.find("head");

        const { language } = this.props;
        //console.log('changeClassCustom===========================', defaultTheme, prevTheme, language)

        await $(_html).removeClass("theme-" + prevTheme);
        await $(_html).addClass("theme-" + defaultTheme);
        let script_url = process.env.PUBLIC_URL + '/charting_library/script_custom.js'
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = script_url;
        await $(_head).append(script);
    }

    async applyTheme(defaultTheme, prevTheme) {
        this.IS_CHANGE_THEME = true
        let overrides = this.load_overrides();
        let theme = defaultTheme === "light" ? "Light" : "Dark";
        await this.tvWidget.changeTheme(theme)
        await this.tvWidget.applyOverrides(overrides.objOverrides);
        await this.changeClassCustom(defaultTheme, prevTheme)
    };

    async componentDidMount() {
        this.mounted = true
        // Defer widget initialize util we get valid symbol and account
        const { currentSymbol } = this.props;
        // await getAccessToken

        // if (currentSymbol) {
        //     this.initWidget();
        // }
        this.initWidget();
    }

    componentDidUpdate(prevProps) {
        const { currentSymbol, language, defaultTheme } = this.props;
        const { currentSymbol: prevCurrentSymbol, language: prevLanguage, defaultTheme: prevTheme } = prevProps;
        if (currentSymbol) {
            // Widget already initiated
            if (this.tvWidget) {
                // May this code can be replace with queue somehow?
                if (defaultTheme !== prevTheme) {
                    if (this.tvWidgetReady && this.tvWidget) {
                        // this.tvWidget.changeTheme(defaultTheme === "light" ? "Light" : "Dark")
                        this.applyTheme(defaultTheme, prevTheme);
                        // this.initWidget(currentSymbol.id)
                    } else {
                        this.tvWidget.onChartReady(async () => {
                            this.applyTheme(defaultTheme, prevTheme);
                            // this.initWidget(currentSymbol.id)
                        });
                    }
                }
                if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
                    if (this.tvWidgetReady && this.tvWidget) {
                        this.tvWidget.setSymbol(currentSymbol.id, this.tvWidget.activeChart().resolution());
                    } else {
                        this.tvWidget.onChartReady(() => {
                            this.tvWidget.setSymbol(currentSymbol.id, this.tvWidget.activeChart().resolution());
                        });
                    }
                }
                if (language !== prevLanguage) {
                    if (this.tvWidgetReady && this.tvWidget) {
                        if (this.tvWidget._options && this.tvWidget._options.locale) {
                            this.tvWidget._options.locale = language
                            this.initWidget(currentSymbol.id)
                        }

                        // this.tvWidget.setLanguage(language);
                        // this.initWidget(currentSymbol.id)
                    } else {
                        this.tvWidget.onChartReady(async () => {
                            if (this.tvWidget._options && this.tvWidget._options.locale) {
                                this.tvWidget._options.locale = language
                                this.initWidget(currentSymbol.id)
                            }
                            // this.tvWidget.setLanguage(language);
                            // this.initWidget(currentSymbol.id)
                        });
                    }
                }
            } else {
                this.initWidget();
            }
        }
        else {
            // Nếu không có mã hoặc mã bị cập nhật về null ===> set lại symbol về rỗng
            if (this.tvWidget) {
                if (this.tvWidgetReady && this.tvWidget) {
                    this.tvWidget.setSymbol('', this.tvWidget.activeChart().resolution());
                } else {
                    this.tvWidget.onChartReady(() => {
                        this.tvWidget.setSymbol('', this.tvWidget.activeChart().resolution());
                    });
                }
            } else {
                this.initWidget();
            }

            if (this.tvWidget) {
                if (defaultTheme !== prevTheme) {
                    if (this.tvWidgetReady) {
                        this.applyTheme(defaultTheme, prevTheme);
                    } else {
                        this.tvWidget.onChartReady(() => {
                            this.applyTheme(defaultTheme, prevTheme);
                        });
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        let self = this
        window.addEventListener('storage', self.callbackWindow);
        emitter.removeListener("onReadyChartByDefaultTheme", this.callback);
        if (this.tvWidget) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
        this.mounted = false
    }

    render() {
        let { showChart } = this.state
        //console.log('The symbol is changed this.props.isCheckSymbolDataFeed: ', this.props.isCheckSymbolDataFeed)
        let containerId = `${this.props.containerId}${this.props.callerId}`
        let styleChart = showChart ? { height: '100%' } : { height: '100%',  'display': 'none' }
        return (
            <div
                    // id={this.props.containerId}
                    id={containerId}
                    style={styleChart}
                    className={'TVChartContainer'}
            />
        );
    }
}